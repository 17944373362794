import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-best-picture-modal',
  templateUrl: './best-picture-modal.component.html',
  styleUrls: ['./best-picture-modal.component.scss'],
})
export class BestPictureModalComponent implements OnInit {

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() convertApiUrl: string;
  title: string = 'Potwierdź wygraną zdjęcia';
  password: string;


  constructor(private modalController: ModalController) { }

  ngOnInit() {}

  cancel() {
    this.modalController.dismiss(null, 'cancel');

  }

  pickWinner() {

    this.modalController.dismiss(this.password, 'submit');
  }
}
