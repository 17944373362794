import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'questions',
    loadChildren: () => import('./pages/questions/questions.module').then(m => m.QuestionsPageModule)
  },
  {
    path: 'pictures',
    loadChildren: () => import('./pages/pictures/pictures.module').then(m => m.PicturesPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
