import {SessionFinderComponent} from "./session-finder/session-finder.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {NotFoundDataComponent} from "./not-found-data/not-found-data.component";
import {BestPictureModalComponent} from "./best-picture-modal/best-picture-modal.component";

@NgModule({
  declarations: [
    SessionFinderComponent,
    NotFoundDataComponent,
    BestPictureModalComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
    SessionFinderComponent,
    NotFoundDataComponent,
    BestPictureModalComponent
  ]
})
export class ComponentsModule { }
