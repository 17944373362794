import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {
  ActionSheetController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
  ToastOptions
} from '@ionic/angular';
// @ts-ignore
import {ToastButton} from '@ionic/core/dist/types/components/toast/toast-interface';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from "@angular/platform-browser";
// @ts-ignore
import {SplashScreen} from "@capacitor/splash-screen";

@Injectable({
  providedIn: 'root'
})
export class CommonUtils {
  public infoToast: any;

  constructor(
    // private storage: Storage,
    private router: Router,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private http: HttpClient,
    private modalController: ModalController,
    private sanitizer: DomSanitizer,
  ) {
  }

  // isFunction(functionToCheck) {
  //   return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  // }
  //
  // debounce(reconnectFrequencySeconds, func, wait) {
  //   let timeout;
  //   let waitFunc;
  //
  //   const that = this;
  //
  //   return function() {
  //     if (that.isFunction(wait)) {
  //       waitFunc = wait;
  //     }
  //     else {
  //       waitFunc = () => wait;
  //     }
  //
  //     const context = this;
  //     const args = arguments;
  //     const later = () => {
  //       timeout = null;
  //       func.apply(context, args);
  //     };
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, waitFunc(reconnectFrequencySeconds));
  //   };
  // }
  //
  // delayedExec(func, timeout?: number): void {
  //   if (func && timeout) {
  //     const that = this;
  //     if (that.isFunction(func)) {
  //       setTimeout(func, timeout);
  //     }
  //   }
  // }
  //
  // getRootFilesFolder() {
  //   return this.file.dataDirectory;
  // }
  //
  // async createFolderIfNotExists(path: string, folderName: string): Promise<DirectoryEntry> {
  //   await this.platform.ready();
  //   if (this.platform.is('cordova') && !this.platform.is('mobileweb') && path && folderName) {
  //     path = path.replace(this.getRootFilesFolder(), '');
  //
  //     const pathSegments: string[] = path.split('/');
  //     console.log('Path segments: ' + pathSegments);
  //     console.log(`Checking folder ${folderName} in ${path}`);
  //
  //     const rootDirectoryEntry = await this.file.resolveDirectoryUrl(`${this.getRootFilesFolder()}`);
  //     let currentDirEntry = rootDirectoryEntry;
  //
  //     for (const parentFolder of pathSegments) {
  //       console.log('checking segment ' + parentFolder);
  //       await this.file.getDirectory(currentDirEntry, parentFolder, {create: true}).then((directoryEntry) => {
  //         console.log(`Folder ${parentFolder} in ${path} was created or already existed`);
  //         currentDirEntry = directoryEntry;
  //       }, error => {
  //         console.log(`Folder already exists`);
  //         return null;
  //       });
  //     }
  //
  //     await this.file.getDirectory(currentDirEntry, folderName, {create: true}).then((directoryEntry) => {
  //       console.log(`Folder ${folderName} in ${path} was created or already existed`);
  //       currentDirEntry = directoryEntry;
  //     }, error => {
  //       console.log(`Folder already exists`);
  //       return null;
  //     });
  //
  //     return currentDirEntry;
  //   }
  // }
  //
  // async createVetReservationFilesFolderIfNotExists(visitUUID: string, callback?): Promise<void> {
  //   if (visitUUID) {
  //     try {
  //       const mediaDirectoryEntry: DirectoryEntry = await this.createFolderIfNotExists(this.getVetReservationFilesFolder(visitUUID), 'media');
  //       const mediaThumbnailsDirectoryEntry: DirectoryEntry = await this.createFolderIfNotExists(this.getVetReservationFilesFolder(visitUUID), 'media-thumbnails');
  //
  //       if (mediaDirectoryEntry && mediaThumbnailsDirectoryEntry) {
  //         if (callback) {
  //           callback({'mediaDirectoryEntry': mediaDirectoryEntry, 'mediaThumbnailsDirectoryEntry': mediaThumbnailsDirectoryEntry});
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  // }
  //
  // async createPetFilesFolderIfNotExistsWithCallback(petUUID: string, callback?): Promise<void> {
  //   if (petUUID) {
  //     try {
  //       const mediaDirectoryEntry: DirectoryEntry = await this.createFolderIfNotExists(this.getPetFilesFolder(petUUID), 'media');
  //       const mediaThumbnailsDirectoryEntry: DirectoryEntry = await this.createFolderIfNotExists(this.getPetFilesFolder(petUUID), 'media-thumbnails');
  //
  //       if (mediaDirectoryEntry && mediaThumbnailsDirectoryEntry) {
  //         if (callback) {
  //           callback({'mediaDirectoryEntry': mediaDirectoryEntry, 'mediaThumbnailsDirectoryEntry': mediaThumbnailsDirectoryEntry});
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  // }
  //
  // createVisitFilesFolderIfNotExists(visitUUID: string) {
  //   if (visitUUID) {
  //     this.createFolderIfNotExists(this.getVisitsFilesFolder(), visitUUID).then(res => {
  //       // ok
  //     });
  //   }
  // }
  //
  // copyFileToLocalDir(fullPath, copyTo: string, newFileName?: string): Promise<boolean> {
  //   let myPath = fullPath;
  //   // Make sure we copy from the right location
  //   if (fullPath.indexOf('file://') < 0) {
  //     myPath = 'file://' + fullPath;
  //   }
  //
  //   const name = myPath.substr(myPath.lastIndexOf('/') + 1);
  //   const copyFrom = myPath.substr(0, myPath.lastIndexOf('/') + 1);
  //
  //   return this.file.moveFile(copyFrom, name, copyTo, (newFileName ? newFileName : name)).then(
  //     success => {
  //       return true;
  //     },
  //     error => {
  //       console.log('error: ', error);
  //       return false;
  //     }
  //   );
  // }
  //
  // getTempFileDir(): string {
  //   return this.platform.is('android') ? this.file.externalCacheDirectory : this.file.tempDirectory;
  // }
  //
  // getVisitsFilesFolder() {
  //   // @ts-ignore
  //   return `${this.getRootFilesFolder()}${environment.VET_RESERVATION_FILES_FOLDER}/`;
  // }
  //
  // getVetReservationFilesFolder(visitUUID) {
  //   return `${this.getVisitsFilesFolder()}${visitUUID}`;
  // }
  //
  // getAssetsRootFilesFolder() {
  //   // @ts-ignore
  //   return `${this.getRootFilesFolder()}${environment.ASSETS_ROOT_DIRECTORY}/`;
  // }
  //
  // getPetsFilesFolder() {
  //   // @ts-ignore
  //   return `${this.getRootFilesFolder()}${environment.PETS_FILES_FOLDER}/`;
  // }
  //
  // getPetFilesFolder(petUUID) {
  //   return `${this.getPetsFilesFolder()}${petUUID}`;
  // }
  //
  //
  // getFakeRequestDelay() {
  //   return this.getEnvironmentConfigProperty('FAKE_REQUEST_DELAY');
  // }
  //
  // getEnvironmentConfigProperty(propertyName) {
  //   return environment[propertyName];
  // }
  //
  // bestCopyEver(src) {
  //   return Object.assign({}, src);
  // }
  //
  // bestTypedCopyEver<T>(target: T, src) {
  //   return Object.assign(target, src);
  // }
  //
  // bestTypedArrayCopyEver<TT, T>(elementObj: TT, target: T, src: Array<any>) {
  //   if (Array.isArray(target) && Array.isArray(src)) {
  //     src.forEach((arrElement) => {
  //       elementObj = {} as TT;
  //       target.push(this.bestTypedCopyEver<TT>(elementObj, arrElement));
  //     });
  //   }
  //   return target;
  // }
  //
  // formGroupValuesToObject<T>(target: T, formGroup: FormGroup) {
  //   if (formGroup && formGroup.valid) {
  //     return Object.assign(target, formGroup.value);
  //   } else {
  //     return null;
  //   }
  // }
  //
  // dateToMinutes(date) {
  //   if (this.isNotEmpty(date)) {
  //     return date.getHours() * 60 + date.getMinutes();
  //   }
  // }
  //
  // getMinutesTillDate(date: Date): number {
  //   if (this.isNotEmpty(date)) {
  //     const now = new Date();
  //     const diffMs = date.getTime() - now.getTime();
  //     return Math.floor((diffMs / 1000) / 60);
  //   }
  //   return null;
  // }
  //
  // hhmmToMinutes(minutesHHMM) {
  //   const t = minutesHHMM.split(':');
  //
  //   return (60 * parseInt(t[0], 2)) + (parseInt(t[1], 2));
  // }
  //
  // isFormFieldInvalid(form: FormGroup, fieldName: string, validationType: string) {
  //   return form.get(fieldName).hasError(validationType) && form.get(fieldName).touched;
  // }
  //
  // isFormInvalid(form: FormGroup, validationType: string) {
  //   return form.errors && form.errors[validationType];
  // }

  // getStorageObject<T>(key: string): Promise<T> {
  //   return this.storage.get(key);
  // }
  //
  // setStorageObject(key: string, value: any): Promise<any> {
  //   return this.storage.set(key, value);
  // }
  //
  // removeStorageObject(key: string): Promise<any> {
  //   return this.storage.remove(key);
  // }
  //
  // getLocalStorageObject(key: string): any {
  //   if (window.localStorage !== undefined) {
  //     const itemJson = window.localStorage.getItem(key);
  //     if (this.isNotEmpty(itemJson)) {
  //       try {
  //         return JSON.parse(itemJson);
  //       } catch (err) {
  //       }
  //     }
  //   }
  //
  //   return null;
  // }
  //
  // setLocalStorageObject(key: string, obj: any): void {
  //   if (window.localStorage !== undefined && this.isNotEmpty(obj)) {
  //     try {
  //       window.localStorage.setItem(key, JSON.stringify(obj));
  //     } catch (err) {
  //     }
  //   }
  //
  //   return null;
  // }
  //
  // removeLocalStorageObject(key: string): void {
  //   if (window.localStorage !== undefined) {
  //     try {
  //       window.localStorage.removeItem(key);
  //     } catch (err) {
  //     }
  //   }
  //
  //   return null;
  // }

  // generateUUID() {
  //   let d = new Date().getTime();
  //   // tslint:disable-next-line:only-arrow-functions
  //   const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  //     // tslint:disable-next-line:no-bitwise
  //     const r = (d + Math.random() * 16) % 16 | 0;
  //     d = Math.floor(d / 16);
  //     // tslint:disable-next-line:no-bitwise
  //     return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  //   });
  //   return uuid;
  // }
  //
  // isNotEmpty(obj) {
  //   return !this.isEmpty(obj);
  // }
  //
  // isEmpty(obj) {
  //   if (obj == null) {
  //     return true;
  //   }
  //
  //   if (!isNaN(obj)) {
  //     if (obj === 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //
  //   for (const key in obj) {
  //     if (obj.hasOwnProperty(key)) {
  //       return false;
  //     }
  //   }
  //
  //   return true;
  // }
  //
  // fill0(numberValue) {
  //   if (!isNaN(numberValue)) {
  //     return (numberValue < 10 ? '0' + numberValue : numberValue);
  //   }
  // }
  //
  // dateFromString(str: string) {
  //   const a = str.split(/[^0-9]/).map(s => parseInt(s, 10));
  //   return new Date(a[0], a[1] - 1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
  // }
  //
  // date2Str(date) {
  //   if (this.isNotEmpty(date)) {
  //     date = new Date(date);
  //     const year = date.getYear() + 1900;
  //     const month = date.getMonth() + 1;
  //     const day = date.getDate();
  //
  //     return year + '-' + this.fill0(month) + '-' + this.fill0(day);
  //   }
  //
  //   return null;
  // }

  // getCurrentDateFormatted(format) {
  //   return moment().format(format);
  // }
  //
  // reformatDate(str: string, format: string) {
  //   return moment(this.dateFromString(str)).format(format);
  // }
  //
  // getFormattedDate(date: Date, format: string) {
  //   return moment(date).format(format);
  // }

  // findInObjectsArray(arr, key: string, value: string) {
  //   if (this.isEmpty(arr) || this.isEmpty(key) || this.isEmpty(value)) {
  //     return -1;
  //   }
  //   for (let i = 0; i < arr.length; i++) {
  //     // console.log(arr[i][key] + ' : ' + value)
  //     if (arr[i][key] === value) {
  //       return i;
  //     }
  //   }
  //   return -1;
  // }
  //
  // findObjectsArray(arr, key: string, value: string) {
  //   if (this.isEmpty(arr) || this.isEmpty(key) || this.isEmpty(value)) {
  //     return -1;
  //   }
  //   let idx = 0;
  //   for (const el of arr) {
  //     if (arr[idx][el] === value) {
  //       return arr[idx];
  //     }
  //     idx++;
  //   }
  //   return undefined;
  // }
  //
  // goTo(page: string) {
  //   this.router.navigateByUrl(page);
  // }
  //
  // goToCmd(commands: string[], callback?) {
  //   this.router.navigate(commands).then(() => {
  //     if (callback) { callback(); }
  //   });
  // }

  // isNotOnWifi(): Promise<boolean> {
  //   return this.platform.ready().then(() => {
  //     return Network.getStatus().then(status => {
  //       console.log(status);
  //       if (status && status.connectionType !== 'wifi') {
  //         return true;
  //       } else {
  //         console.log('wifi');
  //         return false;
  //       }
  //     }, error => {
  //       return true;
  //     });
  //   }, error => {
  //     return true;
  //   });
  // }
  //
  // isOnLine(): Promise<boolean> {
  //   return this.platform.ready().then(() => {
  //     return Network.getStatus().then(status => {
  //       console.log(status);
  //       if (status && status.connected) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }, error => {
  //       return false;
  //     });
  //   }, error => {
  //     return false;
  //   });
  // }

  async presentInfoToastIfNotExistent(message: string) {
    if (this.infoToast) {
      this.infoToast = await this.toastController.create({
        header: message,
        position: 'bottom',
        buttons: [{
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
        ]
      });
      const that = this;

      this.infoToast.present();
    }
  }

  async presentInfoToast(message: string, alertPosition: 'top' | 'bottom' | 'middle' = 'bottom', durationTime: number = 0) {
    const toast = await this.toastController.create({
      header: message,
      position: alertPosition,
      duration: durationTime,
      buttons: [{
        text: 'Ok',
        role: 'cancel',
        handler: () => {
        }
      }
      ]
    });
    toast.present();
  }

  async presentInfoToastIfRouteIsActive(message: string, alertPosition: 'top' | 'bottom' | 'middle' = 'bottom', durationTime: number = 0, testRoute: string) {
    console.log(this.router.url);
    console.log(testRoute);
    console.log(this.router.isActive(testRoute, true));
    if (this.router.isActive(testRoute, true)) {
      const toast = await this.toastController.create({
        header: message,
        position: alertPosition,
        duration: durationTime,
        buttons: [{
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
        ]
      });
      return toast.present();
    } else {
      return Promise.resolve();
    }
  }

  async presentSimpleInfoToast(message: string, alertPosition: 'top' | 'bottom' | 'middle' = 'bottom', durationTime: number = 0) {
    const toast = await this.toastController.create({
      header: message,
      position: alertPosition,
      duration: durationTime
    });
    toast.present();
  }

  async presentYesNoActionSheet(title: string, yesBtnTitle: string, noBtnTitle: string, yesIconTitle: string, noIconTitle: string, yesHandler, noHandler, addCancelBtn = false, subHeader: string = null) {
    const actionSheetOptions = {
      header: title,
      subHeader: undefined,
      buttons: [{
        text: yesBtnTitle,
        role: 'destructive',
        icon: yesIconTitle,
        handler: yesHandler
      }, {
        text: noBtnTitle,
        role: 'destructive',
        icon: noIconTitle,
        handler: noHandler
      }]
    };

    if (subHeader) {
      actionSheetOptions.subHeader = subHeader;
    }

    if (addCancelBtn) {
      actionSheetOptions.buttons.push({
        text: 'Anuluj',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });
    }

    const actionSheet = await this.actionSheetController.create(actionSheetOptions);
    await actionSheet.present();
  }

  async presentComplexInfoToast(message: string, toastPosition: 'bottom' | 'middle' | 'top', toastButtons: ToastButton[]) {
    const toast = await this.toastController.create({
      header: message,
      position: toastPosition,
      buttons: toastButtons
    });
    toast.present();
  }

  async dismissToast() {
    await this.toastController.dismiss();
  }

  async presentAlertToast(message: string, duration: number = 10000) {
    const toastOptions: ToastOptions = {
      header: message,
      position: 'bottom',
      color: 'danger',
      buttons: [{
        text: 'Zamknij',
        role: 'cancel',
        handler: () => {
        }
      }]
    };

    if (duration) {
      toastOptions.duration = duration;
    }

    const toast = await this.toastController.create(toastOptions);

    await toast.present();
  }

  async presentLoading(durationTime: number = 0): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingController.create({
      spinner: 'circular',
      translucent: true,
      backdropDismiss: false,
      message: 'Proszę czekać',
      duration: durationTime,
    });
    await loading.present();

    return loading;
  }

  async dismissLoading(cancelCallback: boolean = false) {
    if (cancelCallback) {
      this.loadingController.dismiss(true);
    } else {
      this.loadingController.dismiss();
    }
  }



//   openIAB(url: string, target: '_blank' | '_system' | '_self', exitCallback?: any, loadErrorCallback?: any, options?: InAppBrowserOptions, closeInAppBrowserCallback?: any): Promise<InAppBrowserObject> {
//     return this.platform.ready().then(() => {
//       let inAppBrowser: InAppBrowserObject;
//       let currentUrl: string;
//
//       if (url && target) {
//         const iabOptions: InAppBrowserOptions = (options ? options : {
//           cleardata: 'yes',
//           zoom: 'no',
//           location: 'yes',
//           hideurlbar: 'yes',
//           hidenavigationbuttons: 'yes',
//           toolbarcolor: '#7c55a8',
//           closebuttoncolor: '#ffffff',
//           closebuttoncaption: 'X',
//         });
//
//         inAppBrowser = this.iab.create(url, target, iabOptions);
//
//         if (inAppBrowser) {
//           if (exitCallback !== undefined) {
//             inAppBrowser.on('exit')?.subscribe(event => {
//               if (currentUrl) {
//                 event.url = currentUrl;
//               }
//               exitCallback(event);
//             });
//           }
//
//           if(closeInAppBrowserCallback !== undefined){
//             console.log('closeInAppBrowserCallback')
//             closeInAppBrowserCallback(inAppBrowser);
//           }
//
//           if (loadErrorCallback !== undefined) {
//             inAppBrowser.on('loaderror')?.subscribe(event => {
//               loadErrorCallback(event);
//             });
//           }
//
//           inAppBrowser.on('loadstop')?.subscribe(event => {
//             currentUrl = event.url;
//
// /*
//             if (url.indexOf('vetineo') < 0) {
//               const closeCode = 'document.getElementsByTagName(\'body\')[0].innerHTML += \'<div style="position: fixed;top: 10px;right: 20px;border: 1px solid;padding: 10px;background-color: #4f3069;color:white;font-family: sans-serif;z-index:200000;cursor:pointer"><a style="color: white" id="close-window-btn">Anuluj płatność</a></div>\'';
//               inAppBrowser.executeScript({code: closeCode});
//
//               const excode = '\
//                 function aaa(){\
//                   alert(\'Close window?\');\
//                   console.log(\'injecting iab close handler\');\
//                   var message = {type: "close-iab"};\
//                   var msgJson = JSON.stringify(message);\
//                   if(!webkit.messageHandlers.cordova_iab){\
//                       console.warn("Cordova IAB postMessage API not found!");\
//                       throw "Cordova IAB postMessage API not found!";\
//                   } else{\
//                       console.log("Message sent!");\
//                       webkit.messageHandlers.cordova_iab.postMessage(msgJson);\
//                   }\
//                   return false;\
//                 }\
//               ;';
//
//               inAppBrowser.executeScript({code: excode});
//             }
// */
//           });
//
//           if (inAppBrowser) {
//             inAppBrowser.on('message').subscribe(event => {
//               console.log('iab event:' + event );
//               if (event && event.data && event.data.type) {
//                 if (event.data.type === 'close-iab') {
//                   inAppBrowser.close();
//                   console.log('closing iab window');
//                 }
//               }
//             });
//           }
//         }
//       }
//
//       return inAppBrowser;
//     });
//   }

  // async storeMessages(visitUUID: string, type: 'received' | 'sent' | string, messages: ChatMessage[]) {
  //   if (messages?.length > 0) {
  //     await this.setStorageObject(`${visitUUID}-vet-reservation-${type}-messages`, messages);
  //   }
  // }
  //
  // async getMessages(visitUUID: string, type: 'received' | 'sent' | string): Promise<ChatMessage[]> {
  //   return await this.getStorageObject<ChatMessage[]>(`${visitUUID}-vet-reservation-${type}-messages`);
  // }
  //
  // public storeDashobardMessages(type: 'received' | 'sent' | string, messages: ChatMessage[]) {
  //   if (messages?.length > 0) {
  //     this.setStorageObject(`dashboard-messages`, messages).then(() => {
  //       // ok
  //     });
  //   }
  // }


  getPublicAssetUrl(path: string, fileName: string): string {
    if (path && fileName) {
      // @ts-ignore
      return `${environment.LOCAL_PUBLIC_ASSETS_PATH}/${path}/${fileName}`
    }

    return '';
  }


  async runIfPlatformReady(callback: any, isMobileCheck: boolean = true) {
    await this.platform.ready().then(() => {
      let deviceCheckOk = true;
      console.log('deviceCheckOk')
      if (isMobileCheck && this.platform.is('mobileweb')) {
        deviceCheckOk = false;
      }
      if (deviceCheckOk) {
        if (callback !== undefined) {
          callback();
        }
      }
    })
  }

  normalizeDate(date: string) {
    if (date) return date.replace(' ', 'T');
  }

  getDateTimeFormat() {
    return 'YYYY-MM-dd HH:mm'
  }


  // async captureImage(promptTitle: string): Promise<{ photoPath: string, stats: StatResult }>{
  //   return this.platform.ready().then(async () => {
  //
  //     if (this.platform.is('cordova')) {
  //       await Camera.checkPermissions();
  //
  //       // @ts-ignore
  //       const image = await Camera.getPhoto({
  //         quality: 90,
  //         allowEditing: false,
  //         // @ts-ignore
  //         width: environment.IMAGE_MAX_WIDTH,
  //         promptLabelHeader: promptTitle,
  //         promptLabelCancel: 'Zrezygnuj',
  //         promptLabelPhoto: 'Z biblioteki zdjęć',
  //         promptLabelPicture: 'Zrób zdjęcie',
  //         resultType: CameraResultType.Uri
  //       });
  //
  //       if (image) {
  //         const fileStats = await Filesystem.stat({path: image?.path});
  //         console.log(fileStats)
  //         if (fileStats) {
  //           return { photoPath: image.path, stats: fileStats};
  //         }
  //       }
  //     }
  //
  //     return null;
  //   });
  // }

  // captureImageFallback(): Promise<{ photoPath: string, stats: StatResult }>{
  //   return this.platform.ready().then(async () => {
  //
  //     if (this.platform.is('cordova')) {
  //       await Camera.checkPermissions();
  //
  //       const options: CaptureImageOptions = {
  //       };
  //       const capturedImages: MediaFile[] | CaptureError = await this.mediaCapture.captureImage(options);
  //
  //       if (Array.isArray(capturedImages) && capturedImages?.length > 0) {
  //         const mf = capturedImages[0];
  //         if (mf) {
  //           console.log(mf)
  //           const fileStats = await Filesystem.stat({path: mf?.fullPath});
  //           if (fileStats) {
  //             console.log(fileStats)
  //             return { photoPath: fileStats.uri, stats: fileStats};
  //           }
  //         }
  //       }
  //     }
  //
  //     return null;
  //   });
  // }

  getSafeUrl(url): any {
    if (url) return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  uuidObjectIndex(index, item: { uuid: string}) {
    return (item?.uuid ? item.uuid : index);
  }


  // async storeImage(url: string, imageName: string, storePath: string = undefined, successCallback: any = undefined, fileEntryNotFoundCallback: any = undefined) {
  //   if (imageName) {
  //     const fileTransfer: FileTransferObject = this.transfer.create();
  //     console.log('start download : ' + url);
  //
  //     if (!storePath) { // @ts-ignore
  //       storePath = `${this.getAssetsRootFilesFolder()}${environment.CACHED_IMG_FOLDER}`;
  //     }
  //
  //     fileTransfer.onProgress(progressEvent => {
  //       if (progressEvent.lengthComputable) {
  //         // Calculate the percentage
  //         console.log(progressEvent.loaded + ' / ' + progressEvent.total);
  //       }
  //     });
  //
  //     console.log(`Trying to save in ${storePath}/${imageName}`)
  //
  //     fileTransfer.download(url, `${storePath}/${imageName}`, true, {
  //       chunked: false,
  //       headers: {
  //         Connection: "close"
  //       }
  //     }).then((entry) => {
  //       console.log('download complete: ' + entry.toURL());
  //       if (successCallback !== undefined) {
  //         successCallback();
  //       }
  //     }, (error) => {
  //       console.log(error)
  //       // handle error
  //     });
  //   } else {
  //     console.log(' Can\'t create file ');
  //     if (fileEntryNotFoundCallback !== undefined) {
  //       fileEntryNotFoundCallback();
  //     }
  //   }
  // }

  getGotoCommandsFromStringPath(goToPath): string[] {
    const gotoCmds = goToPath?.split('/');
    if (gotoCmds && gotoCmds.length > 0) {
      if (goToPath.indexOf('vet-reservations') >= 0) gotoCmds[gotoCmds.length - 1] = gotoCmds[gotoCmds.length - 1] + '?' + (new Date()).getTime();
      return gotoCmds;
    }
  }
}


