import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CommonUtils} from '../../utils/common';
import {PslwmzSession} from "../../model/pslwmz-session.model";


@Component({
  selector: 'app-item-finder',
  templateUrl: './session-finder.component.html',
  styleUrls: ['./session-finder.component.scss'],
})
export class SessionFinderComponent implements OnInit, OnDestroy {


  @Input()
  title: string;
  @Input()
  nameToFind: string;
  @Input()
  items: PslwmzSession[];
  chosenItem: PslwmzSession;
  foundItems: PslwmzSession[];
  @Input()
  fontSize;

  constructor(private modalController: ModalController,
              private commonUtils: CommonUtils) { }

  ngOnInit() {
    this.foundItems = this.items;
    if(!this.fontSize) {
      this.fontSize = 16;
    }
  }

  cancel() {
    this.modalController.dismiss({session: null, fontSize: this.fontSize}, 'cancel');
  }


  prepareFoundItems() {
    if (this.nameToFind.length >= 3) {
      this.foundItems = this.items.filter(session =>
        session.name.toLowerCase().includes(this.nameToFind.toLowerCase())
        || session.speakerNames.find((speakerName) => speakerName.toLowerCase().includes(this.nameToFind.toLowerCase())
        ));
    } else {
      this.foundItems = this.items;
    }

  }

  onItemChosen(item: PslwmzSession) {

    this.chosenItem = item;
    this.modalController.dismiss({session: this.chosenItem,
    fontSize: this.fontSize}, 'submit');
  }

  ngOnDestroy() {

  }

  increaseFontSize() {
    if(this.fontSize < 24) {
      this.fontSize = this.fontSize + 2;
    }

  }
  decreaseFontSize() {
    if(this.fontSize > 12) {
      this.fontSize = this.fontSize - 2;
    }

  }

  getLines(item: PslwmzSession) {
    if(!item.speakerNames || item.speakerNames.length === 0 || (item.speakerNames.length === 1 && item.speakerNames[0] === '')) {
      return 'full';
    }
    return 'none';
  }

  getChipColor(index: number) {

    const numberModulo = index % 3;
    switch (numberModulo) {
      case 0:
        return 'primary';
      case 1:
        return 'success';
      case 2:
        return 'secondary';
    }
  }
}
